import {useEffect, useMemo, useState} from "react";
import {InvestorRelationsView} from "./style";
import {useTranslation} from "react-i18next";
import Announcements_CN from 'assets/investor/CN/Announcements_CN.json'
import Announcements_EN from 'assets/investor/EN/Announcements_EN.json'
import Announcements_HK from 'assets/investor/HK/Announcements_HK.json'
import Circulars_CN from 'assets/investor/CN/Circulars-Proxy Forms_CN.json'
import Circulars_EN from 'assets/investor/EN/Circulars-Proxy Forms_EN.json'
import Circulars_HK from 'assets/investor/HK/Circulars-Proxy Forms_HK.json'
import Company_CN from 'assets/investor/CN/Company Information Sheets_CN.json'
import Company_EN from 'assets/investor/EN/Company Information Sheets_EN.json'
import Company_HK from 'assets/investor/HK/Company Information Sheets_HK.json'
import Corporate_CN from 'assets/investor/CN/Corporate Governance_CN.json'
import Corporate_EN from 'assets/investor/EN/Corporate Governance_EN.json'
import Corporate_HK from 'assets/investor/HK/Corporate Governance_HK.json'
import Documents_CN from 'assets/investor/CN/Documents on Display_CN.json'
import Documents_EN from 'assets/investor/EN/Documents on Display_EN.json'
import Documents_HK from 'assets/investor/HK/Documents on Display_HK.json'
import Financial_CN from 'assets/investor/CN/Financial Reports_CN.json'
import Financial_EN from 'assets/investor/EN/Financial Reports_EN.json'
import Financial_HK from 'assets/investor/HK/Financial Reports_HK.json'
import { useSelector, useDispatch } from "react-redux";
import Progress from '../../components/Progress'
import {
  AppState,
  CATEGORIESMAP,
  LANGUAGE_ENUM,
  fetchRelationshipLists,
  fetchRelationship
} from "../../store/app";

import {
  findFileName,
  findFileUrl
} from '../../utils/formatPage'
import { display } from "@mui/system";

interface IData{
  data: string
  title: string
  pdf_url: string
  children?: {
    data: string
    title: string
    pdf_url: string
  }[]
}
const pageSize = 15

export default function InvestorRelations() {
  const {t} = useTranslation()
  const {
    language,
    subMenu,
    categories,
    relationshipFileType1,
    relationshipFileType2,
    relationshipFileType3,
    relationshipFileType4,
    relationshipFileType5,
    relationshipFileType6
  } = useSelector(
    ({ AppReducer }: { AppReducer: AppState }) => AppReducer
  );
  const dispatch = useDispatch<any>()

  //  const filterUrl = (url:string) => {
  //   // change to local static file
  //   // return url
  //   const newUrl = url.replace('www.lotoie.com', process.env.REACT_APP_SERVER_NAME);
  //   return newUrl
  // }

  useEffect(() => {
    if(!categories.length) return
    dispatch(fetchRelationshipLists(categories))
  }, [language, categories])


  const titles = useMemo(() => {
    if (!subMenu.relationship.length) return []
    return subMenu.relationship
  }, [subMenu])


  // const announcements:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Announcements_CN,
  //     [LANGUAGE_ENUM.en_US]: Announcements_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Announcements_HK
  //   }[language] || Announcements_EN
  // }, [language])
  
  // const [announcementstCurrent, setAnnouncementstCurrent] = useState(1);
  // const showAnnouncementst = useMemo(() => {
  //   return announcements.slice(0, (announcementstCurrent) * pageSize);
  // }, [pageSize, announcementstCurrent, announcements]);
  const [announcementstCurrent, setAnnouncementstCurrent] = useState(1);

  const handleRequestAnn = () => {
    setAnnouncementstCurrent(announcementstCurrent + 1)
    dispatch(fetchRelationship({
      id: announcements.file_type,
      pageSize,
      page: announcementstCurrent
    }))
  }

  const announcements: any = useMemo(() => {
    if (!relationshipFileType1.list) return
    return relationshipFileType1
  }, [language, relationshipFileType1])


  // const financialReports:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Financial_CN,
  //     [LANGUAGE_ENUM.en_US]: Financial_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Financial_HK
  //   }[language] || Financial_EN
  // },[language])
  // const [financialReportsCurrent, setFinancialReportsCurrent] = useState(1);
  // const showFinancialReports = useMemo(() => {
  //   return financialReports.slice(0, (financialReportsCurrent) * pageSize);
  // }, [pageSize, financialReportsCurrent, financialReports]);

  const [financialReportsCurrent, setFinancialReportsCurrent] = useState(1);

  const handleRequestFina = () => {
    setFinancialReportsCurrent(financialReportsCurrent + 1)
    dispatch(fetchRelationship({
      id: financialReports.file_type,
      pageSize,
      page: financialReportsCurrent
    }))
  }

  const financialReports: any = useMemo(() => {
    if (!relationshipFileType2.list) return
    return relationshipFileType2
  }, [language, relationshipFileType2])


  // const circulars:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Circulars_CN,
  //     [LANGUAGE_ENUM.en_US]: Circulars_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Circulars_HK
  //   }[language] || Circulars_EN
  // }, [language])

  // const [circularsCurrent, setCircularsCurrent] = useState(1);
  // const showCirculars = useMemo(() => {
  //   return circulars.slice(0, (circularsCurrent) * pageSize);
  // }, [pageSize, circularsCurrent, circulars]);
  
  const [circularsCurrent, setCircularsCurrent] = useState(1);

  const handleRequestCirc = () => {
    setCircularsCurrent(circularsCurrent + 1)
    dispatch(fetchRelationship({
      id: circulars.file_type,
      pageSize,
      page: circularsCurrent
    }))
  }

  const circulars: any = useMemo(() => {
    if (!relationshipFileType3.list) return
    return relationshipFileType3
  }, [language, relationshipFileType3])
  
    // const companys:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Company_CN,
  //     [LANGUAGE_ENUM.en_US]: Company_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Company_HK
  //   }[language] || Company_EN
  // },[language])

  // const [companysCurrent, setCompanysCurrent] = useState(1);
  // const showCompanys = useMemo(() => {
  //   return companys.slice(0, (companysCurrent) * pageSize);
  // }, [pageSize, companysCurrent, companys]);
  const [companysCurrent, setCompanysCurrent] = useState(1);

  const handleRequestCom = () => {
    setCompanysCurrent(companysCurrent + 1)
    dispatch(fetchRelationship({
      id: companys.file_type,
      pageSize,
      page: companysCurrent
    }))
  }

  const companys: any = useMemo(() => {
    if (!relationshipFileType4.list) return
    return relationshipFileType4
  }, [language, relationshipFileType4])

  
  // const corporateGovernance:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Corporate_CN,
  //     [LANGUAGE_ENUM.en_US]: Corporate_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Corporate_HK
  //   }[language] || Corporate_EN
  // },[language])

  // const [corporateGovernanceCurrent, setCorporateGovernanceCurrent] = useState(1);
  // const showCorporateGovernance = useMemo(() => {
  //   return corporateGovernance.slice(0, (corporateGovernanceCurrent) * pageSize);
  // }, [pageSize, corporateGovernanceCurrent, corporateGovernance]);
  
  const [corporateGovernanceCurrent, setCorporateGovernanceCurrent] = useState(1);

  const handleRequestCor = () => {
    setCorporateGovernanceCurrent(corporateGovernanceCurrent + 1)
    dispatch(fetchRelationship({
      id: corporateGovernance.file_type,
      pageSize,
      page: corporateGovernanceCurrent
    }))
  }

  const corporateGovernance: any = useMemo(() => {
    if (!relationshipFileType5.list) return
    const committesFile = relationshipFileType5.list.filter((item: any) => item.committees_file)
    const normalFile = relationshipFileType5.list.filter((item:any)=> !item.committees_file)

    return {
      count: relationshipFileType5.count,
      currentCount: relationshipFileType1.list.length + 1,
      committesFile,
      normalFile
    }
  }, [language, relationshipFileType5])

  // const [documentsOnDisplayCurrent, setDocumentsOnDisplayCurrent] = useState(1);
  // const showDocumentsOnDisplay = useMemo(() => {
  //   return documentsOnDisplay.slice(0, (documentsOnDisplayCurrent) * pageSize);
  // }, [pageSize, documentsOnDisplayCurrent, documentsOnDisplay]);

  //   const documentsOnDisplay:IData[] = useMemo(() => {
  //   return {
  //     [LANGUAGE_ENUM.zh_CN]: Documents_CN,
  //     [LANGUAGE_ENUM.en_US]: Documents_EN,
  //     [LANGUAGE_ENUM.zh_HK]: Documents_HK
  //   }[language] || Documents_EN
  //   }, [language])

  const [documentsOnDisplayCurrent, setDocumentsOnDisplayCurrent] = useState(1);

  const handleRequestDoc = () => {
    setDocumentsOnDisplayCurrent(documentsOnDisplayCurrent + 1)
    dispatch(fetchRelationship({
      id: documents.file_type,
      pageSize,
      page: documentsOnDisplayCurrent
    }))
  }

  const documents: any = useMemo(() => {
    if (!relationshipFileType6.list) return
    return relationshipFileType6
  }, [language, relationshipFileType6])


 

  return <InvestorRelationsView>
    <h1 className="page-title" id='-1'>{t('Investor Relations')}</h1>
    {/* <div className="block-view" id="announcements"> */}
    <div className="block-view" id="0">
      {/* <h2 className="block-title">{t('Announcements')}</h2> */}
      <h2 className="block-title">{ titles[0]}</h2>
      <div className="block-table">
        <div className="block-table-item block-table-title">
          <div>{t('Date of Release')}</div>
          <div>{t('Document')}</div>
        </div>
        <div className="block-table-list">
          {
            announcements?.list ?  announcements.list.map((item, index) => (
              <div className="block-table-item" key={index}>
                <div>{item.release_date}</div>
                <a href={findFileUrl(item, language)} target="_blank">{findFileName(item,language)}</a>
              </div>
            )) : <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />
          }
          {
            announcements?.count > announcements?.list.length + 1 && <div className="load-more">
              <span onClick={() => handleRequestAnn()}>{t('Click to load more')}</span>
            </div>
          }
        </div>
      </div>
    </div>

    {/* <div className="block-view" id="financial-reports"> */}
    <div className="block-view" id="1">
      {/* <h2 className="block-title">{t('Financial Reports')}</h2> */}
      <h2 className="block-title">{ titles[1]}</h2>
      <div className="block-table">
        <div className="block-table-item block-table-title">
          <div>{t('Date of Release')}</div>
          <div>{t('Document')}</div>
        </div>
        <div className="block-table-list">
          {
            financialReports?.list ? financialReports.list.map((item, index) => (
              <div className="block-table-item" key={index}>
                <div>{item.release_date}</div>
                <a href={findFileUrl(item, language)} target="_blank">{findFileName(item,language)}</a>
              </div>
            )) : <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />
          }
          {
            financialReports?.count > financialReports?.list.length + 1 && <div className="load-more">
              <span onClick={() => handleRequestFina()}>{t('Click to load more')}</span>
            </div>
          }
        </div>
      </div>
    </div>
   
    {/* <div className="block-view" id="circulars-proxy-forms"> */}
    <div className="block-view" id="2">
      {/* <h2 className="block-title">{t('Circulars / Proxy Forms')}</h2> */}
      <h2 className="block-title">{titles[2]}</h2>

      <div className="block-table">
        <div className="block-table-item block-table-title">
          <div>{t('Date of Release')}</div>
          <div>{t('Document')}</div>
        </div>
        <div className="block-table-list">
          {
            circulars?.list ? circulars.list.map((item, index) => (
              <div className="block-table-item" key={index}>
                <div>{item.release_date}</div>
                <a href={findFileUrl(item, language)} target="_blank">{findFileName(item,language)}</a>
              </div>
            ))  : <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />
          }
          {
            circulars?.count > circulars?.list.length + 1 && <div className="load-more">
              <span onClick={() => handleRequestCirc()}>{t('Click to load more')}</span>
            </div>
          }
        </div>
      </div>
    </div>

     {/* <div className="block-view" id="company-information-sheets"> */}
    <div className="block-view" id="3">
      {/* <h2 className="block-title">{t('Company Information Sheets')}</h2> */}
      <h2 className="block-title">{titles[3]}</h2>
      <div className="block-table">
        <div className="block-table-item block-table-title">
          <div>{t('Date of Release')}</div>
          <div>{t('Document')}</div>
        </div>
        <div className="block-table-list">
          {
            companys?.list ? companys.list.map((item, index) => (
              <div className="block-table-item" key={index}>
                <div>{item.release_date}</div>
                <a href={findFileUrl(item, language)} target="_blank">{findFileName(item,language)}</a>
              </div>
            )) : <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />
          }
          {
            companys?.count > companys?.list.length + 1 && <div className="load-more">
              <span onClick={() => handleRequestCom()}>{t('Click to load more')}</span>
            </div>
          }
        </div>
      </div>
    </div>

     {/* <div className="block-view" id="corporate-governance"> */}
    <div className="block-view" id="4">
      {/* <h2 className="block-title">{t('Corporate Governance')}</h2> */}
      <h2 className="block-title">{titles[4]}</h2>
      <div className="block-table">
        {
          corporateGovernance?.normalFile.map((item: any, index: number) => (
            <div className="block-table-item2" key={index}>
              <a href={findFileUrl(item, language)} className="block-table-item2-line">{findFileName(item,language)}</a>
            </div>
         ))
        }
        <h5>{t('Committees')}</h5>
        {
          corporateGovernance?.committesFile.map((item: any, index: number) => (
            <div className="block-table-item2" key={index}>
              <a href={findFileUrl(item, language)} className="block-table-item2-line-c">{findFileName(item,language)}</a>
            </div>
         ))
        }
         {!corporateGovernance && <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />}

        {/* {
          showCorporateGovernance.map((item, index) => (
            <div className="block-table-item2" key={index}>
              {
                item.pdf_url ?  <a href={item.pdf_url} className="block-table-item2-line">{item.title}</a> :
                 <div className="block-table-item2-line">{item.title}</div>
              }
              {
                item.children.map((item2, index2) => (
                  <a href={item2.pdf_url} className="block-table-item2-line-c" key={index2}>
                    {item2.title}
                  </a>
                ))
              }
            </div>
          ))
        } */}

        {
         corporateGovernance?.count > corporateGovernance?.currentCount && <div className="load-more">
            <span
              onClick={() => handleRequestCor()}>{t('Click to load more')}</span>
            </div>
        } 
      </div>
    </div>

    {/* <div className="block-view" id="documents-on-display"> */}
      <div className="block-view" id="5">
      {/* <h2 className="block-title">{t('Documents on Display')}</h2> */}
      <h2 className="block-title">{ titles[5]}</h2>
      <div className="block-table">

        <div className="block-table-item block-table-title">
          {/* <div>{t('Date of Release')}</div> */}
          <div>{t('Document')}</div>

        </div>
        <div className="block-table-list">
          {
            documents?.list ? documents.list.map((item, index) => (
              <div className="block-table-item" key={index}>
                <div>{item.release_date}</div>
                <a href={findFileUrl(item, language)} target="_blank">{findFileName(item,language)}</a>
              </div>
            )) :  <Progress sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '150px'}} />
          }
          {
            documents?.count > documents?.list.length + 1 && <div className="load-more">
            <span
              onClick={() => handleRequestDoc()}>{t('Click to load more')}</span>
            </div>
          }
        </div>
      </div>
    </div>

    { !documents?.list ? <>
         <div className="block-view">
      {/* <h2 className="block-title">{t('Documents on Display')}</h2> */}
      <h2 className="block-title" id='5'>'0'</h2>
      <div className="block-table">

        <div className="block-table-item block-table-title">
          {/* <div>{t('Date of Release')}</div> */}
        </div>

      </div> 
      </div>
      </> : null
    }




  </InvestorRelationsView>
}
