import {
  IPage,
  CATEGORIESMAP,
  LANGUAGE_ENUM,
  RESOURCEMAP
} from '../store/app'

const matchH1tag = (str: string): string[] | null =>  {
  return str.match(/<\s*h1[^>]*>(.*?)<\/h1>/g)
}

const dynamicMatchTag = (str: string, targetStr: string) => {
  const reg = new RegExp(`<\s*${targetStr}[^>]*>(.*?)<\/${targetStr}>`, 'g')
  return str.match(reg)
}

const extractContent = (str: string) : string[] | null  => {
  const matchResult = []
  const reg = /(<(\w+)\s?.*?>)([^<].*?[^>])(<\/\2>)/ig
  var match = str.match(reg)
  var reg2 = />(.*?)</
  for (let i = 0; i < match.length; i++) {
    reg2.test(match[i])  
    matchResult.push(RegExp.$1)  
  }
  return matchResult
}

const formatSubMenu = (originStr: string) :string[] | null => {
  const rawH1tags = matchH1tag(originStr)
  if (!rawH1tags) return 
  return rawH1tags.reduce((prev: string[], cur: string) => {
    prev.push(...extractContent(cur)) 
    return prev
  }, [])
}

const formatSection = (originStr: string): string[] | null => {
  const rawH1tags = matchH1tag(originStr)
  if (!rawH1tags) return 
  if(rawH1tags.length === 1) return [originStr]
  let text = originStr
  return rawH1tags.reduce((prev: string[], cur: string, index: number, array: string[]) => {
    if (index === array.length - 1) {
      prev.push(cur + text)
      return prev
    }
    const result = text.split(array[index + 1])
    text = result[1]
    prev.push(index === 0 ? result[0] :cur +result[0])
    return prev
  }, [])
}

const dynamicFormatSection = (originStr: string, targetStr: string): string[] | null => {
  const rawTags = dynamicMatchTag(originStr, targetStr)
  if (!rawTags) return 
  if(rawTags.length === 1) return [originStr]
  let text = originStr
  return rawTags.reduce((prev: string[], cur: string, index: number, array: string[]) => {
    if (index === array.length - 1) {
      prev.push(cur + text)
      return prev
    }
    const result = text.split(array[index + 1])
    text = result[1]
    prev.push(index === 0 ? result[0] :cur +result[0])
    return prev
  }, [])
}

const formatAllSubMenu = (origin: any, sourceData: any[]): IPage  | null => {
  return Object.keys(origin).reduce((prev: any, cur: any) => {
    const matchData = sourceData.find(item => item.page === cur)
    prev[cur] = formatSubMenu(matchData.desc)
    return prev
  }, {})
}

const formatAllSection = (origin: any, sourceData: any[]): IPage  | null => {
  return Object.keys(origin).reduce((prev: any, cur: any) => {
    const matchData = sourceData.find(item => item.page === cur)
    prev[cur] = formatSection(matchData.desc)
    return prev
  }, {})
}

const formatCategories = (list: any, language:LANGUAGE_ENUM) => {
  return list.reduce((prev: string[], cur: any) => {
    prev.push(cur[CATEGORIESMAP[language]])
    return prev
  }, [])
}

const findFileName = (data: any, language: LANGUAGE_ENUM) =>  data[CATEGORIESMAP[language]]

const findFileUrl = (data: any, language) =>  data[RESOURCEMAP[language]]

const sortListByName = (list: any) => {
  const sortedData = list.sort((a, b) => {
  const dateA = new Date(a.release_date);
  const dateB = new Date(b.release_date);
  
  if (dateA.getTime() === dateB.getTime()) {
    const regex = /\((\d+)\)/;
    if (a.title_chinese.includes('(') && b.title_chinese.includes('(')) {
      const numA = Number(a.title_chinese.match(regex)[1]);
      const numB = Number(b.title_chinese.match(regex)[1]);
      return numA - numB;
    }
  } else {
      return dateA > dateB ? -1 : 1;
    }
  });
  return sortedData
}

export {
  extractContent,
  formatSubMenu,
  formatSection,
  formatAllSubMenu,
  formatAllSection,
  dynamicMatchTag,
  dynamicFormatSection,
  formatCategories,
  findFileName,
  findFileUrl,
  sortListByName
}
