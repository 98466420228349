import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { LANG_LOCAL_KEY } from "locales";
import {
  PageType,
  LANGMAP,
  supabaseGetFetch,
  supabaseGetFetchAll,
  supabaseGetListPagination
} from '../api'
import {
  formatAllSubMenu,
  formatAllSection,
  formatCategories,
  sortListByName
} from "utils/formatPage";

export enum THEME_MODE_ENUM {
  light = "light",
  dark = "dark",
}

export enum LANGUAGE_ENUM {
  zh_CN = "zh_CN",
  en_US = "en_US",
  zh_HK="zh_HK"
}

export enum LANGUAGE_NAME_ENUM {
  zh_CN = "中文",
  en_US = "English",
  zh_HK="繁体"
}

export const CATEGORIESMAP = {
  zh_CN: 'title_chinese',
  en_US: 'title_english',
  zh_HK: 'title_traditional'
}

export const RESOURCEMAP = {
  zh_CN: 'file_chinese',
  en_US: 'file_english',
  zh_HK: 'file_chinese'
  // contactus: [],
}

export const PAGEMAP = {
  aboutus:'/about-us',
  business: '/our-business',
  relationship: '/investor-relations'
  // contactus: [],
}

export const FILETYPESTATE = {
  1: 'relationshipFileType1',
  2: 'relationshipFileType2',
  3: 'relationshipFileType3',
  4: 'relationshipFileType4',
  5: 'relationshipFileType5',
  6: 'relationshipFileType6'
}

export interface IRelationship  {
  count: number,
  data: any[],
  fil_Type: number
}

export type IPage = Record<PageType, string[]>

export interface AppState {
  themeMode: string;
  language: LANGUAGE_ENUM,
  page: Omit<IPage, 'relationship'>
  subMenu: IPage & {relationship: any[]},
  categories: any[],
  relationship: any[],
  relationshipFileType1: any,
  relationshipFileType2: any,
  relationshipFileType3: any,
  relationshipFileType4: any,
  relationshipFileType5: any,
  relationshipFileType6: any
}

const defaultPage = {
  aboutus: [],
  business: [],
  contactus: [],
  relationship: []
}

const withoutRelationShip = {
  aboutus: [],
  business: [],
  contactus: [],
}

const defLanguage: LANGUAGE_ENUM = localStorage.getItem(LANG_LOCAL_KEY) as LANGUAGE_ENUM || LANGUAGE_ENUM.en_US

const initialState: AppState = {
  themeMode: "light",
  language: defLanguage,
  page: {...withoutRelationShip},
  subMenu: { ...defaultPage },
  categories: [],
  relationship: [],
  relationshipFileType1: {},
  relationshipFileType2: {},
  relationshipFileType3: {},
  relationshipFileType4: {},
  relationshipFileType5: {},
  relationshipFileType6: {}
};

export const fetchPages = createAsyncThunk('app/fetchPages', async (language: LANGUAGE_ENUM) => {
  const promises = Object.keys(withoutRelationShip).reduce((prev: any, cur: any) => { 
    prev.push(new Promise( (resolve, reject) => {
      supabaseGetFetch(cur, 'language', LANGMAP[language])
      .then((res) => { 
        resolve({ page: cur,...res})
      }).catch(e => {
       reject(e)
     })
    }))
    return prev
  }, [])

  const categoriesPromise = await supabaseGetFetchAll('categories')

  try {
    return await Promise.all([...promises, categoriesPromise])
  } catch (e) {
    console.error('fetchPages error', e)
    return []
  }
})

export const fetchRelationshipLists = createAsyncThunk('app/fetchRelationshipLists', async (categories: any) => {
    const promises = categories.reduce((prev: any, cur: any) => { 
    prev.push(new Promise( (resolve, reject) => {
      supabaseGetListPagination({
        table: 'relationship',
        column: 'file_type',
        index: cur.id,
        rangeStart: 0,
        rangeEnd: 9
      })
      .then((res) => { 
        resolve(res)
      }).catch(e => {
       reject(e)
     })
    }))
    return prev
    }, [])
  
  try {
    return await Promise.all([...promises])
  } catch (e) {
    console.error('fetchRelationshipLists error', e)
    return []
  }
})

export const fetchRelationship = createAsyncThunk('app/fetchRelationship', async (query: {
  id: string,
  pageSize: number,
  page: number
}) => {
   try {
     return await supabaseGetListPagination({
        table: 'relationship',
        column: 'file_type',
        index: query.id,
        rangeStart: 0,
        rangeEnd: (query.pageSize * query.page) + 1
    })
  } catch (e) {
    console.error('fetchRelationship error', e)
    return []
  }
 
})

export const fetchCateogies = createAsyncThunk('app/fetchCateogies', async () => {
  try {
  return await supabaseGetFetchAll('categories')
  } catch (e) {
    console.error('fetchCateogies error', e)
    return []
  }
})


export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setThemeMode: (state) => {
      state.themeMode = "dark";
    },
    setLanguage: (state, action: PayloadAction<LANGUAGE_ENUM>) => {
      localStorage.setItem(LANG_LOCAL_KEY, action.payload);
      state.language = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPages.fulfilled, (state, action) => {
      const categories = action.payload[3].sort((a, b) => a.id - b.id )
      state.categories = categories
      const relationShipcategories = formatCategories(categories, state.language)
      state.page = formatAllSection(withoutRelationShip, action.payload)
      state.subMenu = {
        ...formatAllSubMenu(withoutRelationShip, action.payload),
        relationship: relationShipcategories
      }
    }).addCase(fetchPages.rejected, (state, action) => {
      state.page = action.payload as any
    })

    builder.addCase(fetchRelationshipLists.fulfilled, (state, action) => {
      action.payload.forEach((ele: any, index: number) => {
        if (ele.list.length && ele.file_type === 6) {
          state[`relationshipFileType${index + 1}`] = { ...ele, list: sortListByName(ele.list) }
        } else {
          state[`relationshipFileType${index + 1}`] = ele
        }
      })
    }).addCase(fetchRelationshipLists.rejected, (state, action) => {})

    builder.addCase(fetchRelationship.fulfilled, (state, action: any) => {
      const stateName = FILETYPESTATE[action.payload.file_type]
      if (action.payload.file_type === 6) {
        state[stateName] =  { ...action.payload, list: sortListByName(action.payload.list) }  
      } else {
        state[stateName] = action.payload
      }
    }).addCase(fetchRelationship.rejected, (state, action) => {})

  }
})
export const {
  setThemeMode,
  setLanguage,
} = AppSlice.actions;
export default AppSlice.reducer;
