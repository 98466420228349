import supabase from "plugins/supabase"

export type LANGVALUE = 'traditional' | 'chinese' | 'english'
export type LANGKEY = 'zh_CN' | 'zh_HK' | 'en_US'
export type PageType = 'aboutus' | 'business' | 'contactus' | 'relationship'

export interface IPagination {
  table: string,
  column: string,
  index: string,
  rangeStart: number,
  rangeEnd: number
}


export const LANGMAP: { [key in LANGKEY]: LANGVALUE } = {
  'zh_CN': 'chinese',
  'zh_HK': 'traditional',
  'en_US': 'english'
}

export const supabaseGetFetch = async (table: string, column: string, index: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const { data, error } = await supabase.from(table).select().eq(column, index)
    if (data && !error) {
      resolve(data[0])
    }
    if (!data && error) {
      reject(error)
    }
  })
}

export const supabaseGetFetchAll = async (table: string ): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const { data, error } = await supabase.from(table).select('*')
    if (data && !error) {
      resolve(data)
    }
    if (!data && error) {
      reject(error)
    }
  })
}

export const supabaseGetListPagination = async (data: IPagination) => {
  const { table, column, index, rangeStart, rangeEnd } = data
  return new Promise(async (resolve, reject) => {
    const res = await supabase
      .from(table)
      .select('*', { count: 'exact' })
      .eq(column, index)
      .range(rangeStart, rangeEnd)
      .order('release_date', { ascending: false }) 
  
    if (res.data && !res.error) {
      resolve({
        count: res.count,
        list: res.data,
        file_type: +data.index
      })
    }
    if (!res.data && res.error) {
      reject(res.data)
    }
  })
}


